<template>
  <div>
    <header-slot />
    <b-nav card-header pills class="m-0">
      <b-nav-item
        :to="{ name: 'dispute-results-in-process-cn' }"
        exact
        :exact-active-class="status === 0 ? 'active' : ''"
        :link-classes="['px-3', bgTabsNavs]"
        >IN PROCESS
      </b-nav-item>
      <b-nav-item
        :to="{ name: 'dispute-results-completed-cn' }"
        exact
        :exact-active-class="status === 1 ? 'active' : ''"
        :link-classes="['px-3', bgTabsNavs]"
        >COMPLETED
      </b-nav-item>
    </b-nav>
    <router-view></router-view>
  </div>
</template>
<script>
//components
import TableInProcess from "@/views/commons/components/dispute-results/views/components/TableInProcess.vue";
import TableCompleted from "@/views/commons/components/dispute-results/views/components/TableCompleted.vue";

export default {
  components: {
    TableInProcess,
    TableCompleted,
  },
  computed: {
    status() {
      return this.$route.meta.status;
    },
  },
};
</script>